import React from "react";
import * as PropTypes from "prop-types";
import TagList from "../components/TagList";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/SEO/Seo";
import Content, { HTMLContent } from "../components/Content";
import IntroCanvas from "../components/experimental/IntroCanvas";

const ArtworksExpTemplate = ({
  content,
  contentComponent,
  intro,
  heading,
  tags,
  langKey,
}) => {
  const PageContent = contentComponent || Content;
  return (
    <div className="container content">
      <h1
        style={{
          position: "absolute",
          zIndex: "2",
          top: "100px",
          left: "2em",
        }}
        className="title animated bounceInLeft"
      >
        {heading}
      </h1>
      <div className="hero">
        <IntroCanvas />
      </div>
      <div className="container">
        <section className="section">
          <PageContent className="container content" content={content} />
        </section>
      </div>
      <TagList tags={tags} langKey={langKey} />
    </div>
  );
};

ArtworksExpTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  tags: PropTypes.array,
  langKey: PropTypes.string,
};

class ArtworksExpPage extends React.Component {
  render() {
    const data = this.props.data;
    const { frontmatter } = data.markdownRemark;
    const jsonData = data.allArticlesJson.edges[0].node.articles;
    const image = frontmatter.image.childImageSharp.gatsbyImageData.src;
    const langKey = frontmatter.lang;
    const tags = frontmatter.tags;
    return (
      <Layout
        className="container"
        data={data}
        jsonData={jsonData}
        location={this.props.location}
      >
        <Seo frontmatter={frontmatter} postImage={image} />
        <div>
          <ArtworksExpTemplate
            contentComponent={HTMLContent}
            heading={frontmatter.heading}
            title={frontmatter.title}
            content={data.markdownRemark.html}
            intro={frontmatter.intro}
            tags={tags}
            langKey={langKey}
          />
        </div>
      </Layout>
    );
  }
}

ArtworksExpPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ArtworksExpPage;

export const pageQuery = graphql`
  query ArtworksExpQuery($id: String!) {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    allArticlesJson(filter: { title: { eq: "home" } }) {
      edges {
        node {
          articles {
            en
            it
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        id
        title
        lang
        tags
        image {
          childImageSharp {
            gatsbyImageData(quality: 87, layout: FULL_WIDTH)
          }
        }
        heading
        description
      }
    }
  }
`;
