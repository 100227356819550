import React from "react";
import { Canvas } from "@react-three/fiber";
import Cubes from "./Cubes";
import Lights from "./Lights";
import Environment from "./Environment";

const IntroCanvas = () => {
  return (
    <>
      <Canvas style={{ height: "800px" }}>
        <Cubes />
        <Lights />
        <Environment />
      </Canvas>
    </>
  );
};

export default IntroCanvas;
